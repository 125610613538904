#references{
    background: url('../public/img/met-01.jpg') center top no-repeat;
    background-size: 100vw 100vh;
    height: auto;
    .site-wrapper-inner{
        padding-top: 8%;
        padding-bottom: 100px;
    }
   .site-wrapper-inner {
        vertical-align: middle;
        position :relative;
        height: 100%;
        background: rgba(60, 58, 56, .9);
        & > .container{
            z-index:1;
            position: relative;
        }
    }
     h1 {
        margin-bottom: 100px;
    }
    h1, div {
        color: #fff;
    }
    .wrapper-text.text-center {
        margin-bottom: 50px;
    }
    p.text-for-references {
        font-size: 1.1em;
        font-weight: 400;
    }
    .ref-block {
        height: 350px;
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        &:hover{
            z-index: 0;
            -moz-box-shadow: 0px 0px 50px 5px #1a1a1a;
            -webkit-box-shadow: 0px 0px 50px 5px #1a1a1a;
            -o-box-shadow: 0px 0px 50px 5px #1a1a1a;
            box-shadow: 0px 0px 50px 5px #1a1a1a;
            .bg-color{
                background: rgba(60, 58, 56, 0);
            }
            
        }
        &.grey:hover .ref-name {
            color: #ff7300;
            font-size: 30px;
        }
        &.grey:hover .ref-type {
            font-size: 15px;
            color: #3a3937;
        }
        &.black:hover .ref-name {
            color: #3a3937;
            font-size: 30px;
        }
        &.black:hover .ref-type {
            font-size: 15px;
            color: #3a3937;
        }
        &:hover .ref-name {
            color: #ff7300;
            font-size: 30px;
        }
        &:hover .ref-go {
            opacity: 1;
            bottom: 30px;
        }
        &.black:hover .ref-go {
            opacity: 1;
            bottom: 30px;
            color: #3a3937;
        }
        &.black:hover .ref-hr {
            width: 70%;
            background-color: #3a3937;
        }
        &.grey:hover .ref-hr {
            width: 70%;
            background-color: #3a3937;
        }
        &.grey:hover .ref-go {
            opacity: 1;
            bottom: 30px;
            color: #3a3937;
        }
        &:hover .ref-hr {
            width: 70%;
        }
        .bg-color {
            background: rgba(60, 58, 56, .7);
            height: 100%;
            padding: 30px;
            transition: all linear 0.2s;
        }
        .ref-name {
            color: #fff;
            font-size: 30px;
            transition: all linear 0.2s;
        }
        .ref-type {
            font-size: 15px;
            color: #fff;
        }
       .ref-go {
            opacity: 0;
            transition: all linear 0.2s;
            position: absolute;
            bottom: 20px;
            font-weight: bolder;
            width: 100%;
            span {
                display: block;
                float: left;
            }
        }
        .ref-hr {
            float: left;
            background-color: #fff;
            height: 1px;
            margin-left: 5px;
            margin-top: 12px;
            width: 1px;
            transition: all linear 0.2s;
        }
    }
    img.pic-ref_1 {
        position: absolute;
        right: 0;
        height: 350px;
        opacity: 0.2;
        width: 41%;
        top: 25px;
        z-index:1;
    }
    img.pic-ref_2 {
        position: absolute;
        top: 0;
        left: -35px;
        z-index:1;
    }
}
.refImg{
    border-top-left-radius: 15px; 
    border-bottom-left-radius: 15px;
    object-fit: cover;
    height: 600px;
    min-width:100%;
    @media (max-width:1023.5px){
        height: auto;
        max-height:400px;
        border-top-left-radius: 15px; 
        border-top-right-radius: 15px; 
        border-bottom-left-radius: 0; 
    }
}